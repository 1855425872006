import React, { FC } from 'react';

import classNames from 'classnames';

import styles from './StatsBlock.css';
import { SubscriptionActions } from '../PaymentAndSubscription';

interface StatsBlockProps {
  type: SubscriptionActions;
}

export const StatsBlock: FC<StatsBlockProps> = React.memo(({ type }: StatsBlockProps) => {
  function getTitle() {
    let text;

    switch (type) {
      case SubscriptionActions.RESTART:
        text = `Since unsubscribing you would've:`;
        break;
      case SubscriptionActions.RENEW:
        text = `As a subscriber, you would:`;
        break;
      default:9
        text = `As a subscriber, you are:`;
        break;
    }

    return text;
  }

  const listItems =
    type === SubscriptionActions.RESTART
      ? ['Blocked hundreds of ads', 'Saved hours on video ads']
      : ['Saving 15% on every gem purchase', 'Playing games without disruptive ads'];

  return (
    <div className={classNames(styles.container, { [styles.restart]: type === SubscriptionActions.RESTART })}>
      <div className={styles.title}>{getTitle()}</div>
      <ul className={styles.list}>
        {listItems.map((i, id) => (
          <li key={id}>{i}</li>
        ))}
      </ul>
    </div>
  );
});
